(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_actions/twister-button/views/twister-button.js') >= 0) return;  svs.modules.push('/components/banner_actions/twister-button/views/twister-button.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.banner_actions=_svs.banner_actions||{};
_svs.banner_actions.twister_button=_svs.banner_actions.twister_button||{};
_svs.banner_actions.twister_button.templates=_svs.banner_actions.twister_button.templates||{};
svs.banner_actions.twister_button.templates.twister_button = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-tracking-string=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"clickTrackingString") || (depth0 != null ? lookupProperty(depth0,"clickTrackingString") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"clickTrackingString","hash":{},"data":data,"loc":{"start":{"line":5,"column":52},"end":{"line":5,"column":75}}}) : helper)))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-position=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"position") || (depth0 != null ? lookupProperty(depth0,"position") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"position","hash":{},"data":data,"loc":{"start":{"line":6,"column":34},"end":{"line":6,"column":46}}}) : helper)))
    + "\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a\n  href=\"javascript:void(0);\"\n  class=\"js-banner-actions-twister-button\"\n  data-gamecodename="
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"gameType") || (depth0 != null ? lookupProperty(depth0,"gameType") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"gameType","hash":{},"data":data,"loc":{"start":{"line":4,"column":20},"end":{"line":4,"column":32}}}) : helper)))
    + "\n  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"clickTrackingString") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":2},"end":{"line":5,"column":83}}})) != null ? stack1 : "")
    + "\n  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"position") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":2},"end":{"line":6,"column":54}}})) != null ? stack1 : "")
    + "\n>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>\n";
},"usePartial":true,"useData":true});
Handlebars.partials['components-banner_actions-twister_button-twister_button'] = svs.banner_actions.twister_button.templates.twister_button;
})(svs, Handlebars);


 })(window);