(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_actions/twister-button/assets/javascripts/init.js') >= 0) return;  svs.modules.push('/components/banner_actions/twister-button/assets/javascripts/init.js');
"use strict";


const {
  trackingEvents
} = svs.components.poker.common.constants;
const {
  startPokerTwister,
  launchTwisterFromStorage,
  trackPokerTwisterEvent
} = svs.components.poker.common.util;
const getWidgetPosition = (passedNode, allWidgetsOnPage) => {
  const listOfNodesAndAllItsParents = [];
  for (let node = passedNode; node; node = node.parentNode) {
    listOfNodesAndAllItsParents.push(node);
  }
  for (let index = 0; index < allWidgetsOnPage.length; index++) {
    const currWidget = allWidgetsOnPage[index];
    if (listOfNodesAndAllItsParents.some(node => node === currWidget)) {
      return index + 1;
    }
  }
  return 0;
};
const handleClick = node => {
  const allWidgetsOnPage = document.querySelectorAll('.widget');
  const {
    trackingString,
    gamecodename
  } = node.dataset;
  if (trackingString) {
    const widgetPosition = getWidgetPosition(node, allWidgetsOnPage);
    trackPokerTwisterEvent({
      trackingType: trackingEvents.PROMO_CLICK,
      trackingString,
      position: widgetPosition
    });
  }
  if (gamecodename) {
    startPokerTwister(gamecodename);
  }
};
const setupEventListeners = () => {
  const nodes = document.querySelectorAll('.js-banner-actions-twister-button');
  nodes.forEach(node => {
    node.addEventListener('click', () => {
      handleClick(node);
    });
  });
};
const init = () => {
  launchTwisterFromStorage();
  setupEventListeners();
};

setGlobal('svs.banner_actions.twister_button.init', init());

 })(window);